<template>
  <div data-cy="employee-list-page">
    <div class="d-flex justify-content-between mb-4">
      <ButtonElement
        data-cy="filters-btn"
        no-focus
        :variant="filtersBtnVariant()"
        text="filters"
        @click="onClickFilters"
      >
        <span>{{ $t("button.filters") }}</span>
        <span>{{ filtersCounter() }}</span>
      </ButtonElement>

      <ButtonElement
        data-cy="add-employee-btn"
        variant="primary"
        text="add"
        @click="onClickAddEmployee"
      />
    </div>

    <KTCard card-class="card-table">
      <OurTable
        data-cy="employees-table"
        table-classes="our-table table-responsive-xl"
        :pagination-data="paginationData"
        :items="employeeList"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="getEmployees"
      >
        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="edit-employee-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEditEmployee(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import dateMixin from "@/mixins/date.mixin";
import { employeesData } from "./gql/queries";
import { serializeFilters } from "@/services/filterBuilder.service";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ButtonElement from "@/components/ButtonElement";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";

const CONTENT_WIDTH = 1000;

export default {
  name: "EmployeeList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ButtonElement,
    PageModal,
    OurFilters,
  },

  mixins: [apolloMixin, dateMixin],

  data() {
    return {
      roles: [],
      paginationData: {},
      employees: [],
      appliedFilters: {},
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "firstName",
          label: this.$t("label.firstName"),
          tdAttr: {
            style: "width: 20%; max-width: 110px;",
          },
          filter: {
            component: "input",
            type: "text",
            column: "user.firstName",
            operator: "like",
          },
        },
        {
          key: "lastName",
          label: this.$t("label.lastName"),
          tdAttr: {
            style: "width: 20%; max-width: 110px;",
          },
          filter: {
            component: "input",
            type: "text",
            column: "user.lastName",
            operator: "like",
          },
        },
        {
          key: "role",
          label: this.$t("label.role._"),
          tdAttr: {
            style: "width: 20%; max-width: 110px;",
          },
          filter: {
            component: "select",
            column: "user.roles.id",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "email",
          label: this.$t("label.email"),
          type: "email",
          tdAttr: {
            style: "width: 25%; max-width: 95px;",
          },
          filter: {
            component: "input",
            type: "text",
            column: "user.email",
            operator: "like",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("employee", ["employeesFilters"]),
    ...mapGetters("role", ["roleLabel"]),

    employeeList() {
      return this.employees.map((employee) => {
        const role = employee.user.roles[0].name;

        return {
          id: employee.user.id,
          dateCreate: employee.user.createdAt,
          firstName: employee.user.firstName,
          lastName: employee.user.lastName,
          email: employee.user.email,
          role: this.roleLabel(role),
        };
      });
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "role") {
          field.filter.selectOptions = this.roleList;
        }

        return field;
      });
    },

    roleList() {
      return this.roles
        .filter((role) => role.parent?.code === "internalUser")
        .map((role) => ({
          ...role,
          label: this.roleLabel(role.name),
        }));
    },
  },

  async created() {
    if (this.employeesFilters) this.appliedFilters = this.employeesFilters;
    await this.getEmployees();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("employee", ["SET_EMPLOYEES_FILTERS"]),

    async getEmployees(page) {
      const sortingOptions = {
        column: "createdAt",
        direction: "desc",
      };
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      if (page && page === this.paginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fields, this.appliedFilters);
      }

      const { employees, roles } = await this.$get(employeesData, {
        filtersOptions,
        sortingOptions,
        page,
      });

      this.paginationData = employees.paginatorInfo;
      this.employees = employees.data;
      this.roles = roles;
    },

    onClickFilters() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    async onApplyFilters(filters) {
      this.appliedFilters = filters;

      this.SET_EMPLOYEES_FILTERS(filters);
      await this.getEmployees();
    },

    async onResetFilters() {
      this.appliedFilters = {};
      const filters = null;

      this.$refs.filtersModal.closeModal();
      this.SET_EMPLOYEES_FILTERS(filters);

      await this.getEmployees();
    },

    onClickAddEmployee() {
      this.$router.push({ name: "EmployeeAdd" });
    },

    onClickEditEmployee({ id }) {
      this.$router.push({ name: "EmployeeEdit", params: { id } });
    },

    filtersBtnVariant() {
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter = Object.keys(this.appliedFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>
