import gql from "graphql-tag";

export const updateEmployee = gql`
  mutation updateEmployee(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $language: String!
    $roleId: ID
  ) {
    updateEmployee(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      language: $language
      roleId: $roleId
    ) {
      user {
        id
        firstName
        lastName
        email
        language
      }
      id
    }
  }
`;

export const deleteEmployee = gql`
  mutation deleteEmployee($userId: ID!) {
    deleteEmployee(userId: $userId) {
      id
      user {
        id
      }
    }
  }
`;
