<template>
  <div>
    <KTCard v-if="employee" data-cy="employee-edit-page">
      <EmployeeForm
        ref="employeeForm"
        :employee="employee"
        :roles="roles"
        @submitForm="onSubmitForm"
      />

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          />
        </div>
      </template>
    </KTCard>

    <ConfirmModal
      :modal-title="$t('page.employeeEdit.modalTitle')"
      is-centered
      @actionConfirmed="onConfirmDeleteEmployee"
    >
      <template v-slot:body>
        {{ $t("page.employeeEdit.modalDescription") }}
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { employeeFormData } from "./gql/queries";
import { updateEmployee, deleteEmployee } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import EmployeeForm from "@/views/employee/employees/_components/EmployeeForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";

const CONTENT_WIDTH = 600;

export default {
  name: "EmployeeEdit",
  components: {
    KTCard,
    EmployeeForm,
    ButtonElement,
    ConfirmModal,
  },

  mixins: [apolloMixin],

  data: () => ({
    employeeId: null,
    employee: null,
    roles: [],
  }),

  async created() {
    this.employeeId = this.$route.params.id;

    await this.getFormData();
  },

  mounted() {
    this.$setBreadcrumbs(["EmployeeList"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getFormData() {
      const { employee, roles } = await this.$get(employeeFormData, { userId: this.employeeId });

      this.employee = employee;
      this.roles = roles;
    },

    onClickSubmit() {
      this.$refs.employeeForm.submitForm();
    },

    async onSubmitForm(employeeData) {
      employeeData = {
        userId: this.employeeId,
        ...employeeData,
      };

      await this.$post(updateEmployee, employeeData);
    },

    onClickBack() {
      this.$router.push({ name: "EmployeeList" });
    },

    async onConfirmDeleteEmployee() {
      const deletedEmployee = await this.$post(
        deleteEmployee,
        { userId: this.employeeId },
        { delaySuccessNotify: true }
      );

      if (deletedEmployee) await this.$router.push({ name: "EmployeeList" });
    },
  },
};
</script>
