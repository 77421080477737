import gql from "graphql-tag";

export const employeeFormData = gql`
  query employeeFormData($userId: ID!) {
    employee(userId: $userId) {
      user {
        id
        firstName
        lastName
        email
        language
        createdAt
        deleted
        roles {
          id
          name
        }
      }
      id
      deleted
    }

    roles {
      id
      name
      code
      isSelectable
      parent {
        id
        name
        code
      }
    }
  }
`;
