import gql from "graphql-tag";

export const createEmployee = gql`
  mutation createEmployee(
    $firstName: String!
    $lastName: String!
    $email: String!
    $language: String!
    $roleId: ID
  ) {
    createEmployee(
      firstName: $firstName
      lastName: $lastName
      email: $email
      language: $language
      roleId: $roleId
    ) {
      user {
        id
        firstName
        lastName
        email
        language
      }
      id
    }
  }
`;
