<template>
  <div data-cy="employee-form">
    <b-row class="mb-7">
      <b-col cols="9">
        <InputElement
          v-model="form.firstName"
          data-cy="first-name-input"
          required
          :with-error="errors.firstName"
          :error-message="$t('validation.required')"
          :label="$t('label.firstName')"
          @blur="removeErrorClass('firstName')"
          @input="removeErrorClass('firstName')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="9">
        <InputElement
          v-model="form.lastName"
          data-cy="last-name-input"
          required
          :with-error="errors.lastName"
          :error-message="$t('validation.required')"
          :label="$t('label.lastName')"
          @blur="removeErrorClass('lastName')"
          @input="removeErrorClass('lastName')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="9">
        <InputElement
          v-model="form.email"
          data-cy="email-input"
          required
          input-type="email"
          :with-error="errors.email"
          :error-message="emailErrorMsg"
          :label="$t('label.email')"
          @blur="removeErrorClass('email')"
          @input="removeErrorClass('email')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-10 mb-7" />

    <b-row>
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.role)"
          data-cy="role-select"
          :options="roleList"
          required
          :with-error="errors.role"
          :error-message="$t('validation.required')"
          :label="$t('label.role._')"
          @select="onSelectRole"
          @open="removeErrorClass('role')"
        />
      </b-col>

      <b-col v-if="isExistEmployee" cols="6">
        <InputElement disabled :label="$t('label.dateCreate')" :value="form.dateCreate" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/services/i18n.service";
import dateMixin from "@/mixins/date.mixin";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";

export default {
  name: "EmployeeForm",
  components: {
    InputElement,
    MultiselectElement,
  },

  mixins: [dateMixin],

  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },

    roles: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    form: {
      dateCreate: "",
      firstName: "",
      lastName: "",
      email: "",
      role: null,
    },
    errors: {
      firstName: false,
      lastName: false,
      email: false,
      role: false,
    },
  }),

  computed: {
    ...mapGetters("role", ["roleLabel"]),

    isExistEmployee() {
      return Object.keys(this.employee).length;
    },

    roleList() {
      return this.roles
        .filter((role) => role.parent?.code === "internalUser")
        .map((role) => ({
          ...role,
          label: this.roleLabel(role.name),
        }));
    },

    emailErrorMsg() {
      return !this.form.email ? this.$t("validation.required") : this.$t("validation.mustBeEmail");
    },
  },

  watch: {
    employee: {
      handler: "onChangeEmployee",
      immediate: true,
      deep: true,
    },
  },

  methods: {
    onChangeEmployee(employeeData) {
      const employee = employeeData?.user;

      if (employee) {
        const employeeRoleId = employee.roles[0].id;
        const role = this.roleList.find((role) => role.id === employeeRoleId);

        this.form.dateCreate = this.$dateConverter(employee.createdAt);
        this.form.firstName = employee.firstName;
        this.form.lastName = employee.lastName;
        this.form.email = employee.email;
        this.form.role = role;
      }
    },

    onSelectRole(role) {
      this.form.role = role;
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;
      this.errors.email = !this.form.email || !this.form.email.match(emailRegExp);
      this.errors.role = !this.form.role;

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const firstName = this.form.firstName;
      const lastName = this.form.lastName;
      const email = this.form.email;
      const roleId = this.form.role.id;
      const language = i18nService.getActiveLanguage();

      const employeeData = {
        firstName,
        lastName,
        email,
        roleId,
        language,
      };

      this.$emit("submitForm", employeeData);
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-group::v-deep {
  margin-bottom: 0;
}
</style>
