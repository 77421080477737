import gql from "graphql-tag";

export const employeesData = gql`
  query employeesData($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    employees(
      orderBy: $orderBy
      filters: $filters
      trashed: WITHOUT
      page: $page
      first: $perPage
    ) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        user(trashed: WITHOUT) {
          id
          firstName
          lastName
          email
          createdAt
          roles {
            id
            name
          }
          language
          deleted
        }
        id
        bx24UserId
        deleted
      }
    }

    roles {
      id
      name
      code
      isSelectable
      parent {
        id
        name
        code
      }
    }
  }
`;
