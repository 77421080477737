<template>
  <KTCard data-cy="employee-add-page">
    <EmployeeForm ref="employeeForm" :roles="roles" @submitForm="onSubmitForm" />

    <template v-slot:footer>
      <div class="d-flex justify-content-start">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { roleList } from "./gql/queries";
import { createEmployee } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import EmployeeForm from "@/views/employee/employees/_components/EmployeeForm";
import ButtonElement from "@/components/ButtonElement";

const CONTENT_WIDTH = 600;

export default {
  name: "EmployeeAdd",
  components: {
    KTCard,
    EmployeeForm,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    roles: [],
  }),

  async created() {
    await this.getRoles();
  },

  mounted() {
    this.$setBreadcrumbs(["EmployeeList"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getRoles() {
      const { roles } = await this.$get(roleList);

      this.roles = roles;
    },

    onClickSubmit() {
      this.$refs.employeeForm.submitForm();
    },

    async onSubmitForm(employeeData) {
      const createdEmployee = await this.$post(createEmployee, employeeData, {
        delaySuccessNotify: true,
      });

      if (createdEmployee) await this.$router.push({ name: "EmployeeList" });
    },

    onClickBack() {
      this.$router.push({ name: "EmployeeList" });
    },
  },
};
</script>
