import gql from "graphql-tag";

export const roleList = gql`
  query roles {
    roles {
      id
      name
      code
      isSelectable
      parent {
        id
        name
        code
      }
    }
  }
`;
